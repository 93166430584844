export default class Slideshow {
	init () {
		if ( jQuery( '.c-slider-gallery' ).length > 0 ) {

			jQuery( '.c-slider-gallery' ).each( function ( id, slider ) {

				jQuery( slider ).find( jQuery( '[data-fancybox="gallery"]' ) ).fancybox( {
					beforeShow: function () {
						this.title = this.title + " - " + jQuery( this.element ).data( "caption" );
					},
					afterShow: function ( instance, slide ) {

						let idx = jQuery.fancybox.getInstance().currIndex;

						jQuery( slider ).find( jQuery( '.c-slider-slick-for' ) ).slick( 'slickGoTo', idx );

					},
					thumbs: {
						showOnStart: true
					},
					hash: false
				} )

				jQuery( slider ).find( jQuery( '.c-slider-slick-for' ) ).slick( {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
					asNavFor: jQuery( slider ).find( jQuery( '.c-slider-slick-nav' ) ),
					dots: false,
					draggable: true,
					accessibility: true,
					infinite: false,
					responsive: [
						{
							breakpoint: 992,
							settings: {
								arrows: false,
								dots: true
							}
						}
					]
				} );

				jQuery( slider ).find( jQuery( '.c-slider-slick-nav' ) ).slick( {
					slidesToShow: 6,
					slidesToScroll: 6,
					asNavFor: jQuery( slider ).find( jQuery( '.c-slider-slick-for' ) ),
					dots: false,
					centerMode: false,
					focusOnSelect: true,
					arrows: false,
					draggable: false,
					accessibility: true,
					infinite: false,
				} );

				jQuery( slider ).find( jQuery( '.c-slider-slick-for' ) ).on( 'init reInit afterChange', function ( event, slick, currentSlide, nextSlide ) {
					if ( currentSlide <= jQuery( slider ).find( jQuery( '.c-slider-slick-nav' ) ).slick.slideCount ) {
						jQuery( slider ).find( jQuery( '.c-slider-slick-nav' ) ).slick( 'slickGoTo', currentSlide, 'true' );
						jQuery( slider ).find( jQuery( '.c-slider-slick-nav .slick-track' ) ).css( 'transform', 'translate3d(0px, 0px, 0px)' );
					}
				} )
			} )
		}
	}
}