export default class Tabs {
    init () {

        jQuery( '.wp-block-typografics-core-tab:first-child' ).addClass( 'active' );
        jQuery( '.wp-block-typografics-core-tabs .c-tab-nav-item:first-child' ).addClass( 'active' );

        jQuery( ".c-tab-nav-item" ).click( function ( e ) {

            e.preventDefault();

            jQuery( '.wp-block-typografics-core-tab' ).removeClass( 'active' );
            jQuery( '.c-tab-nav-item' ).removeClass( 'active' );

            jQuery( '#' + e.target.closest( 'a' ).getAttribute( 'data-tab' ) ).addClass( 'active' );
            jQuery( this ).addClass( 'active' );
        } )
        
    }
}