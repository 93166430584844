export default class Faq {
    init () {
        jQuery( '.faq-item-title' ).click( function ( e ) {
            e.preventDefault();
            let jQuerythis = jQuery( this );
            if ( jQuerythis.next().hasClass( 'faq-item-active' ) ) {
                jQuerythis.next().removeClass( 'faq-item-active' );
                jQuerythis.removeClass( 'faq-item-active' );
                jQuerythis.next().slideUp( 350 );
            } else {
                jQuery( '.faq-item-content' ).removeClass( 'faq-item-active' );
                jQuery( '.faq-item-title' ).removeClass( 'faq-item-active' );
                jQuery( '.faq-item-content' ).slideUp( 350 );
                jQuerythis.next().toggleClass( 'faq-item-active' );
                jQuerythis.toggleClass( 'faq-item-active' );
                jQuerythis.next().slideToggle( 350 );
            }
        }); 
    };
}